import React from 'react'
import { graphql } from 'gatsby'

//components
import RichContent from '../components/content/RichContent'

const TemplatePage = ({ data }) => {
  return (
    <div className='page'>
      <div className='container'>
        <RichContent myRichContent={data.c.content} />
      </div>
    </div>
  )
}

export const query = graphql`
  query ($id: String) {
    c: contentfulPage(id: { eq: $id }) {
      title
      content {
        json
      }
    }
  }
`
export default TemplatePage
